<template>
  <div class='py-2 lg:py-4 sidepanel-horizontal-flush relative'>
    <div class='sticky top-0 left-0 bg-white z-10'>
      <h1 class='mt-0 lg:mt-4 text-xl font-semibold uppercase px-2 lg:px-8'>
        {{ portfolioEntityName }}
      </h1>
      <div class='tab-container border-b mt-4 lg:mt-8 pl-2 lg:pl-8 flex flex-row justify-start text-xs lg:text-sm text-gray-600 overflow-x-auto'>
        <div class='uppercase tracking-wide px-4 lg:px-8 pb-2 pt-4 cursor-pointer hover:bg-gray-100' :class='selectedTab("information")' @click='setActiveTab("information")'>Info</div>
        <div class='uppercase tracking-wide px-4 lg:px-8 pb-2 pt-4 cursor-pointer hover:bg-gray-100' :class='selectedTab("files")' @click='setActiveTab("files")'>Files</div>
        <div class='uppercase tracking-wide px-4 lg:px-8 pb-2 pt-4 cursor-pointer hover:bg-gray-100' :class='selectedTab("update")' @click='setActiveTab("update")'>Updates</div>
      </div>
    </div>
    <component
      :is='tabContentComponent'
      class='mt-4 lg:mt-8'
      @done-editing='doneEditing' />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PiPortfolioInformation  from '@/views/portfolio/PiPortfolioInformation.vue'
import PiInvestmentFiles       from '@/views/dashboard/PiInvestmentFiles.vue'
import InvestmentHistory     from '@/views/dashboard/InvestmentHistory.vue'

export default {
  name: 'PiPortfolioDetails',
  components: {
    PiPortfolioInformation,
    PiInvestmentFiles,
    InvestmentHistory,
  },
  data () {
    return {
      activeTab: 'information',
    }
  },
  computed: {
    ...mapGetters('portfolios', [
      'portfolioEntityName',
    ]),
    tabContentComponent () {
      switch (this.activeTab) {
        case 'information':
          return 'pi-portfolio-information'
        case 'files':
          return 'pi-investment-files'
        case 'update':
          return 'investment-history'
        default:
          return 'div'
      }
    },
  },
  methods: {
    ...mapActions(['sidepanelClose']),
    doneEditing () {
      this.sidepanelClose()
    },
    setActiveTab (tab) {
      this.activeTab = tab
    },
    selectedTab (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
  },
  beforeDestory () {
    this.sidepanelClose()
  },
}
</script>

<style lang='scss' scoped>
  .selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }
</style>
